import { useCurrentRefinements } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import { Button } from '@mui/material'
import { uniq } from 'lodash'

import { setFiltersDrawerOpen, useGlobalParamsContext } from '@/contexts/GlobalParamsContext'

export const FiltersButton = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })
  const { dispatch } = useGlobalParamsContext()
  const openDrawer = () => dispatch(setFiltersDrawerOpen(true))

  const { items } = useCurrentRefinements()

  const appliedFiltersCount = uniq(items.map((item) => item.attribute)).length
  const buttonTextSuffix = appliedFiltersCount === 0 ? '' : ` (${appliedFiltersCount})`

  return (
    <Button
      size="medium"
      onClick={openDrawer}
      data-cy="filters-button"
      variant="outlined"
      sx={{ whiteSpace: 'nowrap' }}
    >
      {t('buttons.filters') + buttonTextSuffix}
    </Button>
  )
}
